<template>
  <div>
    <b-card
        no-body
    >
      <div>
        <b-table
            :items="items"
            :fields="fields"
            striped
            responsive
            class="mb-0 orders-table"
        />
      </div>
    </b-card>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
              v-model="page"
              :total-rows="countItems"
              per-page="20"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BTable, BCard, BRow, BCol, BPagination,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    BPagination,
  },
  data() {
    return {
      fields: [
        { key: 'position', label: 'Тип' },
        { key: 'currency_pair', label: 'Пара' },
        { key: 'price', label: 'Цена' },
        { key: 'quantity', label: 'Количество' },
        { key: 'stop_price', label: 'Цена стоп' },
        { key: 'order_type', label: 'Тип сделки' },
        { key: 'binance_execution_type', label: 'Статус' },
        { key: 'date', label: 'Время' },
      ],
      page: 1,
    }
  },
  computed: {
    ...mapState({
      items: state => state.binanceLogs.binanceLogs,
      countItems: state => state.binanceLogs.countBinanceLogs,
    }),
  },
  watch: {
    page() {
      this.getItems(this.page)
    },
  },
  mounted() {
    this.getItems(1)
  },
  methods: {
    ...mapActions({
      getItems: 'binanceLogs/getItems',
    }),
  },
}
</script>
